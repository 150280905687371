const currentOpts = [
  {
    label: 'current',
    value: 'true'
  },
  {
    label: 'past',
    value: 'false'
  }
];

export default currentOpts;
