const categories = [
  'API',
  'Axios',
  'Bcrypt',
  'CSS',
  'Express',
  'HTML',
  'JavaScript',
  'Mongoose',
  'Node',
  'Passport',
  'React',
  'Redux',
  'Responsive',
  'REST'
];

export default categories;
