export const TOGGLE_THEME = 'TOGGLE_THEME';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const SET_USER = 'SET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const SET_USER_LOADING = 'SET_USER_LOADING';
export const CLEAR_USER_LOADING = 'CLEAR_USER_LOADING';

export const SHOW_ERROR = 'SHOW_ERROR';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECT_LIST = 'SET_PROJECT_LIST';
export const SET_PROJECT_LOADING = 'SET_PROJECT_LOADING';
export const CLEAR_PROJECT = 'CLEAR_PROJECT';

export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_MESSAGE_LIST = 'SET_MESSAGE_LIST';
export const SET_MESSAGE_LOADING = 'SET_MESSAGE_LOADING';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
